/* purgecss start ignore */
.VideoRecorder button[data-qa='turn-on-camera'],
.VideoRecorder button[data-qa='open-input'] {
  @apply py-4 px-6 bg-cool-gray-400 rounded-lg text-white m-2 cursor-pointer transition ease-in-out duration-150 font-normal text-base outline-none;
}

.VideoRecorder button[data-qa='turn-on-camera']:hover,
.VideoRecorder button[data-qa='open-input']:hover {
  @apply bg-cool-gray-500;
}

.VideoRecorder button[data-qa='turn-on-camera']:focus,
.VideoRecorder button[data-qa='open-input']:focus {
  @apply shadow-outline-blue;
}

.VideoRecorder button[data-qa='start-replaying'] {
  display: none;
}
/* purgecss end ignore */
